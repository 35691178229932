// import Vue from 'vue'
import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import routesConfig from '@/utils/routesConfig'
import MenuType from '@/utils/menuType'
// in development env not use Lazy Loading,because Lazy Loading too many pages will cause webpack hot update too slow.so only in production use Lazy Loading

/* layout */
import Layout from '@/components/layouts/main/Layout'
const _import = require('./import_' + process.env.NODE_ENV).default

// Vue.use(Router)

/**
 * icon : the icon show in the sidebar
 * hidden : if `hidden:true` will not show in the sidebar
 * redirect : if `redirect:noredirect` will not redirct in the levelbar
 * noDropdown : if `noDropdown:true` will not has submenu in the sidebar
 * meta : `{ role: ['admin'] }`  will control the page role
 **/
export const constantRouterMap = [{
    name: 'login',
    path: '/login',
    component: _import('/login/index'),
    hidden: true,
    props: true
  },
  {
    // name: 'bad-not-found',
    path: '/:catchAll(.*)',
    component: _import('/404'),
    hidden: true
  },
  {
    name: '仪表盘',
    path: '/db',
    component: Layout,
    redirect: '/dashboard',
    hidden: false,
    icon: 'dashboard',
    type: MenuType.Link
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    hidden: true,
    children: [{
        path: 'home',
        name: '首页',
        hidden: true,
        menuType: MenuType.Link,
        component: _import('/home/home')
      },
      {
        path: 'changepwd',
        name: '修改密码',
        component: _import('/system/admin/changePassword')
      }
    ]
  }
]
const router = createRouter({
  // mode: 'history', //后端支持可开
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
})

/**
 * 路由配置
 * @type {Object}
 */
router.routesConfig = routesConfig
/**
 * push到指定的路由
 * @param  {String | Object} key   路由配置键
 * @param  {*} param 参数
 * @param  {String | Array} breadcrumb 自定义面包屑
 */
router.pushTo = function (key, param, breadcrumb) {
  this.push(this.routesConfig.getRoute(key, param, breadcrumb))
}
/**
 * replace到指定的路由
 * @param  {String | Object} key   路由配置键
 * @param  {*} param 参数
 * @param  {String | Array} breadcrumb 自定义面包屑
 */
router.replaceBy = function (key, param, breadcrumb) {
  this.replace(this.routesConfig.getRoute(key, param, breadcrumb))
}
/**
 * push到指定的带query路由
 * @param  {String | Object} key   路由配置键
 * @param  {Object} query 参数
 * @param  {String | Array} breadcrumb 自定义面包屑
 */
router.pushQueryTo = function (key, query, breadcrumb) {
  this.push(this.routesConfig.getRouteWithQuery(key, query, breadcrumb))
}
/**
 * replace到指定的带query路由
 * @param  {String | Object} key   路由配置键
 * @param  {Object} query 参数
 * @param  {String | Array} breadcrumb 自定义面包屑
 */
router.replaceQueryBy = function (key, query, breadcrumb) {
  this.replace(this.routesConfig.getRouteWithQuery(key, query, breadcrumb))
}


export default router

export const asyncRouterMap = []