import {
  createApp
} from "vue";
import Vue from 'vue'
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "@/permission";
import * as consts from './utils/constant'
import ElementPlus from "element-plus";
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import waves from "@/directive/waves";
import "@/assets/styles/customer-theme.scss";
import IconSvg from "@/components/Icon-svg";
// 第三方
import SvgIcon from "@/components/SvgIcon";
import '@/components/icons'

// import installElementPlus from "./plugins/element";



const app = createApp(App)
.use(ElementPlus,{locale:zhCn})
.use(store)
.use(router)
// .use(consts)
.component("icon-svg", IconSvg)
.component("svg-icon", SvgIcon)
.directive("waves", waves)
app.config.globalProperties.$consts = consts
app.config.globalProperties.$BASE_API = process.env.VUE_APP_BASE_API
app.config.globalProperties.$APP_NAME = process.env.VUE_APP_SYSTEM_NAME
app.config.globalProperties.$EXCEL_UPLOAD_Label = process.env.VUE_APP_EXCEL_UPLOAD_Label
// app.use(ElementPlus,{locale:zhCn}).mount("#app");

app.mount("#app");