import fetch from '@/utils/fetch'
import { saveAs } from 'file-saver'
import {
  ElMessage
} from 'element-plus'
import router from '../router'
import store from '../store'
/**
 * api抽象类
 */
export default class Api {
  servicePath = '/backend';
  modulePath = '';
  constructor (modulePath) {
    this.modulePath = modulePath
  }

  request (config) {
    return fetch(config)
  }

  /**
   * 获取分页数据
   * @param {*} 分页请求数据
   */
  getPaged ({
    pageInfo,
    condition
  }) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/page`,
      method: 'post',
      pageInfo,
      data: condition
    })
  }

  /**
   * 获取查询数据
   * @param {*} 请求参数
   * @param {*} 指定api
   */
  getQuery ({
    pageInfo,
    condition
  }, api, verb) {
    api || (api = 'query')
    verb || (verb = 'post')
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/${api}`,
      method: verb,
      pageInfo,
      data: condition
    })
  }

  /**
   * 获取查询数据
   * @param {*} 请求参数
   * @param {*} 指定api
   */
  getAll () {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/all`,
      method: 'get'
    })
  }

  /**
   * 根据Id获取详情
   * @param {*} 唯一id
   */
  getById (id) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/${id}`,
      method: 'get'
    })
  }

  /**
   * 创建新对象
   * @param {*} 对象内容
   */
  insert (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}`,
      method: 'post',
      data: form
    })
  }

  /**
   * 更新已存在的对象
   * @param {*} 对象内容
   */
  update (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}`,
      method: 'put',
      data: form
    })
  }

  /**
   * 删除对象
   * @param {*} 唯一id
   */
  delete (id) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/${id}`,
      method: 'delete'
    })
  }

  exportAsExcel (appendUrl, condition, newFileName) {
    this.request({
      url: `${this.servicePath}/${this.modulePath}/${appendUrl}`,
      method: 'post',
      data: condition,
      responseType: 'blob'
    }).then(res => {
      var reader = new FileReader()
      reader.readAsText(res.data, 'utf-8')
      reader.onload = function () {
        try {
          var jsonData = JSON.parse(reader.result)
          if (jsonData.status) {
            res.config.showError && jsonData.msg && ElMessage({
              message: jsonData.msg,
              type: 'warning'
            })
          }
        } catch (error) {
          const blob = new Blob([res.data])
          saveAs(blob, newFileName)
        }
      }
    }).catch(error => {
      var reader = new FileReader()
      reader.readAsText(error.response.data, 'utf-8')
      reader.onload = function () {
        var jsonData = JSON.parse(reader.result)
        var message = error.message
        switch (error.response.status) {
          case 401:
            store.dispatch('FedLogOut')
            message = (jsonData && jsonData.status) ? jsonData.message : '登录已超时，请重新登录'
            router.replace({
              name: 'login',
              params: {
                lastPath: router.history.current.fullPath
              }
            })
            break
          case 500:
            message = '服务器内部错误'
            if (jsonData && jsonData.status && jsonData.status > 1000) {
              message = jsonData.msg ? jsonData.msg : message
            }
            break
        }
        ElMessage({
          message: message,
          type: 'error'
        })
      }
    })
  }
}
