export const tenantStatus = {
  Enabled: {
    value: 1,
    title: '启用'
  },
  Disabled: {
    value: 2,
    title: '停用'
  }
}

export const definitions = [
  { title: "OEM项目", option: 1 },
  { title: "ODM项目", option: 2 },
  { title: "预研项目", option: 3 },
  { title: "联合开发项目", option: 4 },
  { title: "派生项目", option: 5 },
]

export const status = [
  { title: "正常", option: 1 },
  { title: "提前结束", option: 2 },
  { title: "正常结束", option: 3 },
  { title: "延期", option: 4 },
]

export const types = [
  { title: "A", option: 1 },
  { title: "B", option: 2 },
  { title: "C", option: 3 },
]

export const levels = [
  { title: "1", option: 1 },
  { title: "2", option: 2 },
  { title: "3", option: 3 },
  { title: "4", option: 4 },
]

export const stages = [
  { title: "零次评审阶段", option: 1 },
  { title: "EAT阶段", option: 2 },
  { title: "DAT阶段", option: 3 },
  { title: "QAT阶段", option: 4 },
  { title: "预研项目阶段", option: 5 },
]

export const MaterialTypes = [
  { type: "通用件", name: 'GENERAL' },
  { type: "借用件", name: 'BORROW' },
  { type: "专用件", name: 'DEDICATED' },
  { type: "限制件", name: 'ASTRICT' },
]

export const UploadSize = 209715200
