<template>
  <svg class="svg-icon" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
  name: 'icon-svg',
  props: {
    iconClass: {
      type: String,
      required: true
    }
  },
  computed: {
    iconName () {
      return `#icon-${this.iconClass}`
    }
  }
}
</script>
<style>
.svg-icon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 10px;
  vertical-align: -.4em;
  fill: currentColor;
  overflow: hidden;
}
</style>

