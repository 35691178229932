function hashCode(str) {
  var hash = 0
  var chr
  if (str.length === 0) return hash;
  for (var i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

import MD5 from "@/utils/md5"

const getters = {
  sidebar: state => state.app.sidebar,
  topbar: state => state.app.topbar,
  projectId: state => state.app.projectId,
  projects: state => state.app.projects,
  account: state => state.user.account,
  accountType: state => state.user.accountType,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  menus: state => state.user.menus,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions.map(p=>{
    p.hashCode = MD5(p.method + p.rest)
    return p
  }),
  permission_routers: state => state.permission.routers,
  menus: state=>state.permission.menus,
  addRouters: state => state.permission.addRouters,
  // filterResult : state=>state.filterDialog.filterResult,
  // filterDialogOpend: state =>state.filterDialog.dialogOpend,
  mainContainerHeight: state => state.mainContainerHeight,
  connection: state => state.app.ws.connection,
  statusChange: state => state.app.ws.statusChange,
  pageDetailList: state => state.page.pageDetailList,
  timmer: state => state.timmer.timmer
}
export default getters
