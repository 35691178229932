<template>
    <!-- <el-menu class="navbar" mode="horizontal"> -->
    <el-row class="navbar" justfy="space-between" align="middle" style="height:100%;background:#0F1D44">
        <el-col >
            <!-- <img :src="logo" /> -->
            <span @click="toggleSideBar" class="site-title">
              <i v-if="sidebar.opened" class="el-icon-menu"></i>
              <i v-else class="el-icon-s-unfold"></i>
            </span>
            <span class="site-title" @click="$router.replace({path:'/'})">{{systemName}}</span>
            
            <!-- <el-tooltip class="item" effect="dark" content="帮助文档" placement="right-start"><a style="margin-left:10px;" :href="helpLink" class="avatar-wrapper" target="_blank"><i class="el-icon-question"></i></a></el-tooltip> -->
        </el-col>

        <div class="avatar-container">
            <el-dropdown  trigger="click">
                <!-- <div class="avatar-wrapper"> -->
                    <el-row type="flex" class="avatar-wrapper" align="middle">
                        <img class="user-avatar" :src="avatar" />
                        <div>{{name}}</div>
                        <i class="el-icon-more"></i>
                    </el-row>
                <!-- </div> -->
                <template #dropdown >
                    <el-dropdown-menu class="user-dropdown">
                        <router-link class='inlineBlock' to="/">
                            <el-dropdown-item>
                                首页
                            </el-dropdown-item>
                        </router-link>
                        <router-link class='inlineBlock' :to="{name: '修改密码'}">
                            <el-dropdown-item divided>
                                修改密码
                            </el-dropdown-item>
                        </router-link>
                        <el-dropdown-item divided>
                            <span @click="logout" style="display:block;">注销</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <!-- 全屏功能暂不启用 -->
            <el-tooltip class="item" effect="dark" content="全屏" placement="bottom" :show-after="700">
              <i class="el-icon-rank screen-full" @click="$store.dispatch('ToggleFullScreen')"></i>
            </el-tooltip>
        </div>
        <!-- <el-col >
        </el-col> -->
    </el-row>
    <!-- </el-menu> -->
</template>
<style lang="scss" scoped>
.navbar {
  margin: 0 -20px;
  padding-left: 18px;
  height: 100%;
  line-height: 60px;
  border-radius: 0px !important;
  border-bottom: solid 0px #000 !important;
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.5);
  background: #fff;
  .el-row {
    outline: 0 none;
  }
  img {
    width: 82px;
    height: 22px;
    // margin-top: 19px;
  }
  .site-title-icon{
    margin-left: 10px;
    cursor: pointer;
  }
  .el-icon-rank{
    color: rgba(230, 236, 245, 1);
  }
  .el-icon-more{
    color: rgba(230, 236, 245, 1);
  }
  .site-title {
    margin-left: 10px;
    // width: 56px;
    // height: 20px;
    font-size: 18px;
    font-family: SourceHanSansSC-Medium;
    color: rgba(230, 236, 245, 1);
    line-height: 20px;
    cursor: pointer;
  }
  .errLog-container {
    display: inline-block;
    position: absolute;
    right: 150px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 35px;
    // width: 200px;
    /*color: #fff;*/
    .avatar-wrapper {
      cursor: pointer;
      margin-top: 5px;
      position: relative;
      height: 100%;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }
      div {
        margin-left: 10px;
        margin-top: 10px;
        color: #fff;
      }
      .el-icon-more {
        // position: absolute;
        // margin-left: 10px;
        // margin-top: 27px;
        // font-size: 12px;
        transform: rotate(90deg);
        margin-top: 10px;
      }
    }
    .screen-full{
      position: relative;
      top: -8px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import logo from "@/assets/logo.png";
import adminApi from "@/apis/admin/admin";
import { ElMessage } from "element-plus";
// import Levelbar from './Levelbar'
// import Hamburger from '@/components/Hamburger'
export default {
  data() {
    return {
      logo,
      // projects: [],
      projectId: "",
      systemName: process.env.VUE_APP_SYSTEM_NAME,
      helpLink: process.env.VUE_APP_HELP_LINK
    };
  },
  mounted() {
    this.loadProject();
  },
  computed: {
    ...mapGetters(["sidebar", "name", "avatar", "roles", "projects"]),
    // projectId: function(){

    // }
  },
  watch: {
    projectId(nv) {
      if (nv) {
        sessionStorage.setItem("projectId", nv);
      }
    },
    projects: {
      handler: function (newV, oldV) {
        this.loadProject();
      },
      deep: true,
    },
  },
  methods: {
    projectIdChange() {
      const info = this.projects.filter((m) => m.id === this.projectId)[0];
      var i = info.heatingTypes.length;

      if (i > 1) {
        sessionStorage.setItem("heatingTypes", 2);
      } else {
        sessionStorage.setItem("heatingTypes", info.heatingTypes[i - 1].value);
      }

      adminApi.getInfo().then((resp) => {
        const data = resp.data;
        if (!data.roles || data.roles.length <= 0) {
          data.roles = [{ name: "无角色" }];
        }
        // 判断项目类型
        // if (i === 1) {
        //   var menuDevice = data.menus[0].children
        //   if (info.heatingTypes[0].value === 1) {
        //     menuDevice = data.menus[0].children.filter(c => c.code === 'list')
        //   } else if (info.heatingTypes[0].value === 0) {
        //     menuDevice = data.menus[0].children.filter(c => c.code === 'mdchlrslist' || c.code === 'mdchlrsinfo' || c.code === 'mdchlrsplan')
        //   }
        //   data.menus[0].children.splice(0, 6)
        //   menuDevice.forEach(element => {
        //     data.menus[0].children.push(element)
        //   })
        // }

        this.$store.dispatch("GetInfo", data);
        var { roles, menus } = data;
        this.loading = false;
        this.$store.dispatch("GenerateRoutes", { roles, menus }).then(() => {
          this.$router.addRoute(this.$store.getters.addRouters);
          var lastName = this.$route.name;
          var flag = true;
          // if (this.lastPath) {
          //   this.$router.push({ path: this.lastPath })
          // }
          // this.$router.push({ path: '/' })
          const dataRouters = this.$store.getters.addRouters;
          dataRouters.forEach((dataElement) => {
            if (dataElement.children) {
              dataElement.children.forEach((child) => {
                if (child.name === lastName) {
                  flag = false;
                }
              });
            }
          });

          if (flag) {
              if (dataRouters.length > 0 && dataRouters[0].children) {
                this.$router
                  .push({ name: dataRouters[0].children[0].name })
                  .catch(() => {});
              } else {
                this.$message.warning("你没有权限访问")
                this.$router.push({ path: "/login" }).catch(() => {});
              }
          }
          this.$store.dispatch("clearPageDetail");
          this.reload();
        });
      });
    },
    loadProject() {
      // adminApi.getProjectsByCurrentAcount().then(res => {
      //   this.projects = res.data
      //   if (this.projects && this.projects.length > 0) {
      //     var id = sessionStorage.getItem('projectId')
      //     if (id) {
      //       this.projectId = id
      //       this.systemName = this.projects.filter(project => project.id === id)[0].systemName
      //     } else {
      //       this.projectId = this.projects[0].id
      //       this.systemName = this.projects[0].systemName
      //     }
      //   }
      // })
      if (this.projects && this.projects.length > 0) {
        var id = sessionStorage.getItem("projectId");
        if (id) {
          this.projectId = id;
          this.systemName = this.projects.filter(
            (project) => project.id === id
          )[0].systemName;
        } else {
          this.projectId = this.projects[0].id;
          this.systemName = this.projects[0].systemName;
        }
      }
    },
    toggleSideBar() {
      this.$store.dispatch("ToggleSideBar");
    },
    logout() {
      adminApi.logout().then(() => {
        this.$store.dispatch("LogOut").then(() => {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      });
    },
  },
};
</script>
