import {
  getToken,
  setToken,
  removeToken
} from '@/utils/auth'
import avatar from '@/assets/img/avatar.png'
import adminApi from '@/apis/admin/admin'
// import app from './modules/app'

const user = {
  state: {
    account: undefined,
    accountType: {},
    token: getToken(),
    name: '',
    avatar: avatar,
    roles: [],
    permissions: [],
    menus: []
  },

  mutations: {
    SET_ACCOUNT: (state, account) => {
      state.account = account
    },
    SET_ACCOUNT_TYPE: (state, type) => {
      state.accountType = type
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus
    }
  },

  actions: {
    // 登录
    Login({
      commit
    }, {token, type}) {
      return new Promise((resolve, reject) => {
        setToken(token)
        commit('SET_TOKEN', token)
        commit('SET_ACCOUNT_TYPE', type)
        resolve()
      })
    },

    // 获取用户信息
    GetInfo({
      commit,
      state
    }, {
      account,
      roles,
      name,
      avatar,
      permissions,
      menus
    }) {
      return new Promise((resolve, reject) => {
        commit('SET_ACCOUNT', account)
        commit('SET_ROLES', roles)
        commit('SET_MENUS', menus)
        commit('SET_NAME', name)
        commit('SET_PERMISSIONS', permissions)
        commit('SET_AVATAR', avatar || state.avatar)
        resolve()
      })
    },

    // 登出
    LogOut({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_MENUS', [])
        commit('SET_PERMISSIONS', [])
        sessionStorage.clear()
        removeToken()
        resolve()

        // logout(state.token).then(() => {
        //   commit('SET_TOKEN', '')
        //   commit('SET_ROLES', [])
        //   removeToken()
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      })
    },

    // 前端 登出
    FedLogOut({
      commit
    }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_PERMISSIONS', [])
        removeToken()
        sessionStorage.clear()
        resolve()
      })
    }
  }
}

export default user