const data = {
  state: {
    iconsMap: []
  },
  generate (iconsMap) {
    this.state.iconsMap = iconsMap
  }
}

export default data
