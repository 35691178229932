import api from '../api'
/**
 * 管理员用户相关api
 */
export class CustomerApi extends api {
  /**
   * 修改用户状态
   * @param {*} form
   * @returns
   */
  setState(form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/setCustomerStatus`,
      method: 'put',
      data: form
    })
  }
  resetPassword(form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/resetPassword`,
      method: 'post',
      data: form
    })
  }
  /**
   * 获取账号信息
   * @param {*} param0
   */
  getInfo() {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/info`,
      method: 'post'
    })
  }

  /**
   * 登出
   * @param {*} param0
   */
  logout() {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/logout`,
      method: 'post'
    })
  }
}

export default new CustomerApi('customer')