import { asyncRouterMap, constantRouterMap, moduleMap } from "@/router/index";
import MenuType from "@/utils/menuType";
import Layout from "@/components/layouts/main/Layout";
import GroupLayout from "@/components/layouts/main/GroupLayout";
import router from "../../router";
const _import = require("@/router/import_" + process.env.NODE_ENV).default;

/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.role) {
    return roles.some((role) => route.meta.role.indexOf(role) >= 0);
  } else {
    return true;
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(asyncRouterMap, roles) {
  const accessedRouters = asyncRouterMap.filter((route) => {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles);
      }
      return true;
    }
    return false;
  });
  return accessedRouters;
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    menus:[],
    cooperationMenus:[]
  },
  mutations: {
    SET_MENUS:(state,menus)=>{
      state.menus = menus
    },
    SET_COOPERATION_MENUS:(state, menus)=>{
      state.cooperationMenus = menus
    },
    SET_ROUTERS: (state, routers) => {
      // var temp = routers.slice(0);
      // var filterFunc = (self,router) =>{
      //   if (router.children && router.children.length>0) {
      //     router.children = router.children.filter(child=>{
      //       return self(self,child);
      //     });
      //     return true;
      //   }
      //   else if (router.component == Layout) {
      //     return false;
      //   }
      //   else{
      //     return true;
      //   }
      // };
      // state.addRouters = routers.filter(router=>{
      //   return filterFunc(filterFunc,router);
      // });
      // state.addRouters = routers;
      // state.routers = constantRouterMap.concat(routers);
      state.addRouters = routers
      // state.addRouters = state.routers.concat(routers)
    },
  },
  actions: {
    GenerateCooperationMenus({ commit },menus){
      commit("SET_MENUS", menus);
    },
    GenerateRoutes({ commit }, data) {
      return new Promise((resolve) => {
        const { roles, menus } = data;
        commit("SET_MENUS", menus);
        let accessedRouters;
        if (roles.indexOf("admin") >= 0) {
          accessedRouters = asyncRouterMap;
        } else {
          accessedRouters = filterAsyncRouter(asyncRouterMap, roles);
        }

        var recursiveFunc = (self, menu) => {
          var subrouters = [];
          if (menu.children) {
            subrouters = menu.children.map((subobj) => self(self, subobj));
          }
          var {
            url: path,
            title: name,
            type,
            icon,
            children = subrouters,
          } = menu;
          if (subrouters && subrouters.length > 0) {
            children = subrouters;
          }
          var redirect = undefined;
          var component;
          var props = false;

          if (menu.type === MenuType.Router || menu.type === MenuType.Hidden) {
            var re = /\/:\S*/;
            var componentPath = path.replace(re, "");
            props = true;
            try {
              component = _import(componentPath);
            } catch (error) {
              console.debug(error);
              component = _import("/404");
            }
          } else if (menu.type === MenuType.Link) {
            redirect = path;
            path = `/${menu.code}`;
            try {
              component = Layout;
            } catch (error) {
              component = _import("/404");
            }
          }
          // else if (menu.type === MenuType.Groupable && menu.businessType === 'COOPERATION') {
          //   console.log('cooperationLayout',menu.code)
          //   redirect = path;
          //   path = `/${menu.code}`;
          //   try {
          //     component = CooperationLayout;
          //   } catch (error) {
          //     console.log('e',error)
          //     component = _import("/404");
          //   }
          // }
          else {
            if (menu.type === MenuType.Expandable) {
              component = Layout;
            } else {
              component = GroupLayout;
            }
          }
          var noDropdown = subrouters.length <= 0;
          return {
            path,
            name,
            type,
            icon,
            hidden: false,
            noDropdown,
            redirect,
            children,
            component,
            props,
          };
        };

        var routers = menus.map((menu) => {
          return recursiveFunc(recursiveFunc, menu);
        });

        // console.log("routers", routers);
        routers.forEach((element) => {
          if (!element || element === undefined) {
          }
          // accessedRouters.unshift(element);
        });

        routers = routers.concat(accessedRouters);
        commit("SET_ROUTERS", routers);

        resolve();
      });
    },
  },
};

export default permission;
