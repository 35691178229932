<template>
  <el-config-provider :locale="zhCn">
      <router-view></router-view>
  </el-config-provider>
</template>

<script>
import zhCn from 'element-plus/es/locale/lang/zh-cn'
export default {
  name: "App",
  components: {},
  setup(){
    return {
      zhCn
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
};
</script>

<style lang="scss">
@import "~normalize.css"; // normalize.css 样式格式化
@import "@/assets/styles/index.scss"; // 全局自定义的css样式
#app{
  min-width: 1200px;
}
</style>
