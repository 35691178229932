// import Vue from 'vue'
import { createStore } from 'vuex'
import user from './modules/user'
import app from './modules/app'
import permission from './modules/permission'
import page from './modules/page'
import timmer from './modules/timmer'

// import filterDialog from "./modules/filterDialog"
import getters from './getters'

// Vue.use(Vuex)

const store = createStore({
  state: {
    goodsForm: {},
    mainContainerHeight: 0
  },
  mutations: {
    goodsForm: (state, data) => (state.goodsForm = data),
    SET_MAINCONTAINER_HEIGHT: (state, height) => {
      state.mainContainerHeight = height
      // console.log('vuex:' + height)
    }
  },
  actions: {
    UpdateMainContainerHeight ({ commit }, height) {
      commit('SET_MAINCONTAINER_HEIGHT', height)
    }
  },
  modules: {
    page,
    app,
    user,
    permission,
    timmer
    // filterDialog,
  },
  getters
})

export default store
