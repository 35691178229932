import router from './router'
import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import {
  ElMessage
} from 'element-plus'
import {
  getToken
} from '@/utils/auth' // 验权
import adminApi from '@/apis/admin/admin'
import customerApi from '@/apis/customer/customer'
import _MenuItemGroup from 'element-plus/lib/el-menu-item-group'

const whiteList = ['/login']
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getToken()) {
    if (to.path === '/login') {
      if (store.getters.menus.length === 0) {
        NProgress.done()
        store.dispatch('FedLogOut')
        this.$message.warning("你没有权限访问")
        next({
          name: 'login',
          params: {
            lastPath: to.fullPath
          }
        })
      } else {
        for (var i = 0; i < store.getters.menus.length; i++) {
            if (store.getters.menus[i].children && store.getters.menus[i].children.length > 0) {
              next({
                path: store.getters.menus[i].children[0].url
              })
              break
            } 
         }
      }
    } else if (from.path !== '/' && to.matched.length === 0) {
      this.$message.warning("你没有权限访问")
      NProgress.done()
      next(false)
    } else {
      if (store.getters.roles.length === 0) {
        adminApi.getInfo().then(resp => {
          const data = resp.data
          if (!data.roles || data.roles.length <= 0) {
            data.roles = [{
              name: '无角色'
            }]
          }
          store.dispatch('GetInfo', data)
          var {
            roles,
            menus
          } = data
          var cooperationMenus = []
          if(to.path.startsWith('/business/cooperation/')){
            var index = to.path.lastIndexOf("\/");
            var parentPath = to.path.substring(0,index)
            var infoParentPath = parentPath.substring(0,parentPath.lastIndexOf("\/"))
            var recursiveFunc = (self, menu) => {
              var subMenu = [];
              if(menu.url === parentPath && Array.isArray(menu.children) && menu.children.length > 0){
                cooperationMenus.push(menu)
              }
              else if (infoParentPath !== '/business/cooperation' && menu.url === infoParentPath &&Array.isArray(menu.children) && menu.children.length > 0) {
                cooperationMenus.push(menu)
              }
              else if (Array.isArray(menu.children) && menu.children.length > 0) {
                subMenu = menu.children.map((subobj) => self(self, subobj));
              }
            }
            menus.map((menu) => {
              return recursiveFunc(recursiveFunc, menu);
            });
          }
          menus = cooperationMenus.length > 0? cooperationMenus: menus
          store.dispatch('GenerateRoutes', {
            roles,
            menus
          }).then(() => {
            store.getters.addRouters.forEach(r => {
              router.addRoute(r)
            })
            if (to.path === '/home') {
              if (menus.length > 0 && menus[0].children) {
                var pathRoutter = menus[0].children[0].url
                next({
                  path: pathRoutter
                })
              } else {
                next({
                  path: '/404'
                })
              }
            } else {
              next(to)
            }
          })
        })
      } else {
        if (to.path === '/home') {
          if (store.getters.menus.length > 0 && store.getters.menus[0].children) {
            var pathRoutter = store.getters.menus[0].children[0].url
            next({
              path: pathRoutter
            })
          } else {
            next()
          }
        } else {
          next()
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next({
        // path:'/login',
        name: 'login',
        params: {
          lastPath: to.fullPath
        }
      })
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})