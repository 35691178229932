<template>
  <div>
    <template v-for="item in routes">
      <el-submenu v-if="item.type==menuType.Expandable" :key="item.id" :index="item.title">
        <template #title>
          <icon-svg v-if='item.icon' :icon-class="item.icon" />
          <span >{{item.title}}</span>
        </template>
        <sidebar-item class='menu-indent' v-if='item.children&&item.children.length>0' :routes='item.children'> </sidebar-item>
      </el-submenu>
      <el-menu-item-group v-else-if="item.type==menuType.Groupable" :key="item.id">
        <span >{{item.title}}</span>
        <sidebar-item class='menu-indent' v-if='item.children&&item.children.length>0' :routes='item.children'> </sidebar-item>
      </el-menu-item-group>
      <router-link v-else-if="item.type==menuType.Router" class="menu-indent" :to="item.url" :key="item.id" >
        <el-menu-item :index="item.url">
          <icon-svg v-if='item.icon' :icon-class="item.icon" />
          <span >{{item.title}}</span>
        </el-menu-item>
      </router-link>
       <router-link v-else-if="item.type==menuType.Link" class="menu-indent" :to="item.url" :key="item.id">
        <el-menu-item :index="item.url">
          <icon-svg v-if='item.icon' :icon-class="item.icon" />
          <span >{{item.title}}</span>
        </el-menu-item>
      </router-link>
    </template>
  </div>
</template>

<script>
import MenuType from '@/utils/menuType'

export default {
  name: 'SidebarItem',
  props: {
    routes: {
      type: Array
    }
  },
  data () {
    return {
      menuType: MenuType
    }
  }
}
</script>

<style lang="scss">
.el-menu--collapse .el-submenu > .el-submenu__title span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
.el-menu--collapse .el-menu-item span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}

.el-menu--collapse {
  .el-menu-item {
    .el-submenu__icon-arrow {
      display: none;
    }
  }
  .el-submenu {
    .el-submenu__title {
      text-align: center;
      .el-submenu__icon-arrow {
        display: none;
      }
    }
  }
}
</style>
