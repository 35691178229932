
const timmer = {
  state: {
    timmer: undefined
  },

  mutations: {
    SET_TIMMER: (state, timmer) => {
      state.timmer = timmer
    }
  },

  actions: {
    // 获取定时信息
    GetTimmer ({ commit, state }, timmer) {
      return new Promise((resolve, reject) => {
        commit('SET_TIMMER', timmer)
        resolve()
      })
    }

  }
}
export default timmer
