
const pageInfo = {
  state: {
    pageDetailList: [{
      pageListQuery: {
        condition: {
        },
        pageInfo: {
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      },
      pagePath: ''
    }]
  },

  mutations: {
    SET_PAGE_DETAIL_LIST (state, pageDetailList) {
      state.pageDetailList.push(pageDetailList)
    },
    UPDATE_PAGE_DETAIL (state, pageDetail) {
      var arr = []
      arr = state.pageDetailList
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].pagePath === pageDetail.pagePath) {
          state.pageDetailList.splice(i, 1, pageDetail)
        }
      }
    },
    CLEAR_PAGE_DETAIL (state) {
      state.pageDetailList = []
    }
  },

  actions: {
    addPageDetail ({ commit }, pageDetail) {
      return new Promise((resolve, reject) => {
        commit('SET_PAGE_DETAIL_LIST', pageDetail)
        resolve()
      })
    },
    updatePageDetail ({ commit }, pageDetail) {
      return new Promise((resolve, reject) => {
        commit('UPDATE_PAGE_DETAIL', pageDetail)
        resolve()
      })
    },
    clearPageDetail ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_PAGE_DETAIL')
        resolve()
      })
    }
  }
}

export default pageInfo
