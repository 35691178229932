import axios from 'axios'
import {
  ElMessage,
  ElLoading
} from 'element-plus'
import store from '../store'
import {
  getToken
} from '@/utils/auth'
import qs from 'qs'
import router from '../router'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 15000, // 请求超时时间
  showError: true // 是否显示错误
})

// service.defaults.withCredentials = true
/**
 * 自动遮罩相关参数
 */
const loadingText = '拼命加载中'
var requestCount = 0
var loading = null

/**
 * 显示加载框
 * @param {*} config 配置
 */
function autoShowLoading(config) {
  if (typeof config.showLoading === 'undefined' || config.showLoading) {
    if (!loading) {
      loading = ElLoading.service({
        target: '.loading-mask',
        lock: true,
        text: config.loadingText || loadingText
      })
    }
  }
  requestCount++
}

/**
 * 隐藏加载框
 */
function autoHideLoading() {
  if (--requestCount <= 0) {
    requestCount = 0
    if (loading) {
      loading.close()
      loading = null
    }
  }
}

// request拦截器
service.interceptors.request.use(config => {
  autoShowLoading(config)

  var token = null
  if (!store.getters.token) {
    var cookieToken = getToken()
    if (cookieToken) {
      store.commit('SET_TOKEN', cookieToken)
      token = cookieToken
    }
  } else {
    token = store.getters.token
  }
  if (token) {
    config.headers['Authorization'] = `${token}` // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  var projectId = sessionStorage.getItem('projectId')
  // var projectId = '688394819893985280'
  if (projectId) {
    config.headers['X-Project-Id'] = `${projectId}`
  }
  /**
   * 组装分页信息
   */
  if (config.pageInfo) {
    config.headers['X-Page-Index'] = (config.pageInfo.pageIndex ? config.pageInfo.pageIndex : 1) - 1
    config.headers['X-Page-Size'] = config.pageInfo.pageSize ? config.pageInfo.pageSize : 20
    if (Array.isArray(config.pageInfo.orders) && config.pageInfo.orders.length > 0) {
      config.headers['X-Page-Sort'] = config.pageInfo.orders
    }
  }
  if (!config.dataType) {
    config.dataType = 'json'
  }
  if ((config.method === 'post' || config.method === 'put') && config.dataType !== 'json') {
    if (!config.data) {
      config.data = []
    }
    config.data = qs.stringify(config.data)
  }
  return config
}, error => {
  // Do something with request error
  if (error.code === 'ECONNABORTED') {
    ElMessage({
      message: '访问超时啦，请检查您的网络是否正常',
      type: 'error'
    })
  } else {
    ElMessage({
      message: '访问失败啦，网络可能不稳定，请稍后重试',
      type: 'error'
    })
  }
  Promise.reject(error)
  autoHideLoading()
})

// respone拦截器
service.interceptors.response.use(
  response => {
    autoHideLoading()
    if (response.config.responseType === 'blob') {
      return response
    }
    const res = response.data
    // console.log('status', typeof res)
    if (res.status) {
      response.config.showError && res.msg && ElMessage({
        message: res.msg,
        type: 'warning'
      })
      return Promise.reject(res)
    }
    // console.log(response)
    return res
  },
  error => {
    autoHideLoading()
    // 400 bad request 401 auth fail 403 forbid 404 not found 500 internal error
    console.debug({
      'err': error
    }) // for debug
    var message = error.message
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          message = '错误的请求'
          break
        case 401:
          if (error.config.responseType === 'blob') {
            return Promise.reject(error)
          }
          store.dispatch('FedLogOut')
          message = (error.response.data && error.response.data.message) ? error.response.data.message : '登录已超时，请重新登录'
          router.replace({
            name: 'login',
            params: {
              lastPath: router.currentRoute.value.fullPath
            }
          })
          break
        case 403:
          message = '未授权请求'
          break
        case 404:
          message = '请求的资源不存在'
          break
        case 500:
          message = '服务器内部错误'
          if (error.config.responseType === 'blob') {
            return Promise.reject(error)
          }
          if (error.response.data && error.response.data.status && error.response.data.status > 1000) {
            message = error.response.data.msg ? error.response.data.msg : message
          }

          break
      }
    }

    ElMessage({
      message: message,
      type: 'error'
    })
    return Promise.reject(new Error(message))
  }
)

export default service