import Vue from 'vue'
import IconSvg from '@/components/Icon-svg'


// 第三方
import SvgIcon from '@/components/SvgIcon' // svg组件
import generateIconsView from '@/components/svg-icons/generateIconsView.js' // svg组件


// register globally
// Vue.component('icon-svg', IconSvg)

const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./svg', false, /\.svg$/)
const iconMap = requireAll(req) // just for @/views/icons , you can delete it
// Vue.component('svg-icon', SvgIcon)

generateIconsView.generate(iconMap) // just for @/views/icons , you can delete it
