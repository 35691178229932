export default class MenuType {
    /**
     * 根菜单
     */
    static Root = 0
    /**
     * 展开菜单
     */
    static Expandable = 'EXPANDABLE'
    /**
     * 分组菜单
     */
    static Groupable = 'GROUPABLE'
    /**
     * 路由菜单
     */
    static Router = 'ROUTER'
    /**
     * 隐藏菜单
     */
    static Hidden = 'HIDEABLE'
    /**
     * 链接菜单
     */
    static Link = 'LINKED'
}
