// import localforage from 'localforage'
// const localforage = require('localforage')
import Cookies from 'js-cookie'

const TokenKey = 'Bms-X-Token'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}
