<template>
  <el-menu unique-opened text-color="#333" background-color="#fff" class="nav-menu" :default-active="$route.path" :collapse="!sidebar.opened">
    <sidebar-item :routes='menus'></sidebar-item>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
// import Hamburger from '@/components/Hamburger'

export default {
  components: {
    SidebarItem
  },
  computed: {
    ...mapGetters(['permission_routers','menus', 'sidebar'])
  },
  created(){
    console.debug('menus',this.menus)
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch('ToggleSideBar')
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-menu {
  &:not(.el-menu--collapse) {
    width: 220px;
  }
  height: 100%;
}

</style>
