<template>
<el-container :style="`overflow-x:hidden;height:${c1h}px`" ref="container1">
    <el-header class="header-shadow" :class="topbar.expand?'':'hidden'" ref="headerTop">
      <topbar ref="topbar"></topbar>
      <div class="arrow" :class="!topbar.expand?'expand':''" @click="$store.dispatch('ToggleTopbar')"></div>
    </el-header>
  <el-container ref="container2" :style="`height:${c1h-($refs.headerTop?$refs.headerTop.$el.clientHeight:0)}`">
    <el-scrollbar :class="sidebar.opened?'scroll-expand':'scroll-collapse'" class="menu-bar">
      <sidebar></sidebar>
    </el-scrollbar>
    <el-main class="loading-mask">
      <el-container ref="container3" >
        <el-header height="auto">
          <navbar></navbar>
        </el-header>
        <el-main ref="main" style="overflow-y: auto;">
          <router-view ></router-view>
        </el-main>
       <el-footer height="30px">
         <el-row style="height:100%;font-size:12px;color:#C0C4CC;" type="flex" justify="center" align="middle" >
           <el-col :span="6" class="font-color" style="text-align:right;">{{$APP_NAME}}</el-col>
           <el-col :span="6" class="font-color" style="text-align:left;">© <a href="">四川虹美</a></el-col>
         </el-row>
        </el-footer>
      </el-container>
    </el-main>
  </el-container>
</el-container>
</template>
<style lang="scss" scoped>
.header-shadow{
  z-index: 999;
}
.loading-mask {
  position: relative;
  background: #fafafa;
}

.header-shadow{
  margin-top: 0px;
  transition: all 0.5s ease;
  &.hidden{
    margin-top: -60px;
  }
}

.arrow {
  border-width: 0px;
  position: relative;
  display: inline-block;
  top: 0px;
  right: 20px;
  float: right;
  width: 0;
  height: 0px;
  content: '';
  border-style: solid;
  border-color: #fff #fff transparent transparent;
  transform: rotate(135deg);
  box-shadow: 2px -2px 2px #ccc;
  cursor: pointer;
  transition: all 0.5s ease;

  -webkit-animation: glow 800ms ease-out infinite alternate;
	-moz-animation: glow 800ms ease-out infinite alternate;
	-o-animation: glow 800ms ease-out infinite alternate;
	-ms-animation: glow 800ms ease-out infinite alternate;
	animation: glow 800ms ease-out infinite alternate;

  &.expand{
    top: -10px;
    border-width: 8px;
  }
}

// @-webkit-keyframes glow {
//     0% {
// 		border-color: #393 #393 transparent transparent;
// 		box-shadow: 0 0 5px rgba(0,255,0,.2), inset 0 0 5px rgba(0,255,0,.1), 0 2px 0 #000;
//     }	
//     100% {
// 		border-color: #6f6 #6f6 transparent transparent;
// 		box-shadow: 0 0 20px rgba(0,255,0,.6), inset 0 0 10px rgba(0,255,0,.4), 0 2px 0 #000;
//     }
// }

// @-moz-keyframes glow {
//     0% {
// 		border-color: #393 #393 transparent transparent;
// 		box-shadow: 0 0 5px rgba(0,255,0,.2), inset 0 0 5px rgba(0,255,0,.1), 0 2px 0 #000;
//     }	
//     100% {
// 		border-color: #6f6 #6f6 transparent transparent;
// 		box-shadow: 0 0 20px rgba(0,255,0,.6), inset 0 0 10px rgba(0,255,0,.4), 0 2px 0 #000;
//     }
// }

// @-o-keyframes glow {
//     0% {
// 		border-color: #393 #393 transparent transparent;
// 		box-shadow: 0 0 5px rgba(0,255,0,.2), inset 0 0 5px rgba(0,255,0,.1), 0 2px 0 #000;
//     }	
//     100% {
// 		border-color: #6f6 #6f6 transparent transparent;
// 		box-shadow: 0 0 20px rgba(0,255,0,.6), inset 0 0 10px rgba(0,255,0,.4), 0 2px 0 #000;
//     }
// }

// @-ms-keyframes glow {
//     0% {
// 		border-color: #393 #393 transparent transparent;
// 		box-shadow: 0 0 5px rgba(0,255,0,.2), inset 0 0 5px rgba(0,255,0,.1), 0 2px 0 #000;
//     }	
//     100% {
// 		border-color: #6f6 #6f6 transparent transparent;
// 		box-shadow: 0 0 20px rgba(0,255,0,.6), inset 0 0 10px rgba(0,255,0,.4), 0 2px 0 #000;
//     }
// }

@keyframes glow {
    0% {
		border-color: #393 #393 transparent transparent;
		box-shadow: 2px -2px 5px rgba(0,255,0,.2), inset 2px -2px 5px rgba(0,255,0,.1), 0 0 0 #000;
    }	
    100% {
		border-color: #6f6 #6f6 transparent transparent;
		box-shadow: 2px -2px 20px rgba(0,255,0,.6), inset 2px -2px 10px rgba(0,255,0,.4), 0 0 0 #000;
    }
}
</style>

<script>
import Topbar from '@/components/layouts/main/Topbar'
import Navbar from '@/components/layouts/main/Navbar'
import Sidebar from '@/components/layouts/main/Sidebar'
import { mapGetters } from "vuex";
import adminApi from '@/apis/admin/admin'
import elementResizeDetectorMaker from 'element-resize-detector'

export default {
  name: 'layout',
  data () {
    return {
      c1h: 0,
      c2h: 0,
      c3h: 0
    }
  },
  components: {
    Topbar,
    Navbar,
    Sidebar
  },
  computed: {
    sidebar () {
      return this.$store.state.app.sidebar
    },
    ...mapGetters(["topbar"])
  },
  methods: {
    computeContainerSize () {
      var that = this
      var wh = window.innerHeight
      that.c1h = wh
      that.$nextTick(function () {
        var mh = that.$refs.main.$el.clientHeight
        that.c3h = mh
        that.$store.dispatch('UpdateMainContainerHeight', mh)
        sessionStorage.setItem('mainContainerHeight', mh)
      })
    }
  },
  mounted () {
    // var erd = elementResizeDetectorMaker()
    //   erd.listenTo(this.$refs.main.$el,()=>{
    //     this.computeContainerSize()
    //   })
    var that = this
    var wh = window.innerHeight
    that.c1h = wh
    window.onresize = function () {
      // that.computeContainerSize()
      that.$nextTick(function () {
        that.computeContainerSize()
      })
    }
    this.$nextTick(function () {
      that.computeContainerSize()
    })
  }
}
</script>

<style lang="scss" >
.menu-bar {
  flex:none;
  .el-scrollbar__view {
    background: #fafafa;
  }
}
.scroll-expand {
  padding-right: 0;
  /*min-width: 220px;*/
  height: 100%;
  background: #fff;
    .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
            height: 100%;
        }
    }
}
.scroll-collapse {
    // padding-right: 15px;
    /*min-width: 220px;*/
  background: #fff;
    .el-scrollbar__wrap {
        overflow: inherit;
        .el-scrollbar__view {
            height: 100%;
        }
    }
    overflow: initial;
}

</style>
