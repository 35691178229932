import Cookies from 'js-cookie'

const app = {
  state: {
    sidebar: {
      opened: !+Cookies.get('sidebarStatus')
    },
    topbar:{
      expand: true
    },
    ws: {
      connection: false,
      statusChange: {}
    },
    projectId: null,
    projects: []
  },
  mutations: {
    TOGGLE_CONNECT_STATUS: (state, isConnected) => {
      state.ws.connection = isConnected
    },
    UPDATE_DEVICE_CHANGE_STATUS: (state, statusChange) => {
      state.ws.statusChange = statusChange
    },
    TOGGLE_SIDEBAR: state => {
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1)
      } else {
        Cookies.set('sidebarStatus', 0)
      }
      state.sidebar.opened = !state.sidebar.opened
    },
    TOGGLE_TOPBAR: state => {
      state.topbar.expand = !state.topbar.expand
    },
    TOGGLE_FULL_SCREEN: state => {
      const requestMethod = document.body.requestFullScreen || document.body.webkitRequestFullScreen || document.body.mozRequestFullScreen || document.body.msRequestFullScreen
      if (requestMethod) {
        requestMethod.call(document.body)
      }
      state.topbar.expand = false
      state.sidebar.opened = false
    },
    UPDATE_PROJECT: (state, projectId) => {
      state.projectId = projectId
    },
    UPDATE_PROJECTS: (state, projects) => {
      state.projects = projects
    }
  },
  actions: {
    updateWsConnection ({
      commit
    }, isConnected) {
      return new Promise(resolve => {
        commit('TOGGLE_CONNECT_STATUS', isConnected)
        resolve()
      })
    },
    updateStatus ({
      commit
    }, statusChange) {
      return new Promise(resolve => {
        commit('UPDATE_DEVICE_CHANGE_STATUS', statusChange)
        resolve()
      })
    },
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    ToggleTopbar: ({ commit }) => {
      commit('TOGGLE_TOPBAR')
    },
    ToggleFullScreen:({ commit }) => {
      commit('TOGGLE_FULL_SCREEN')
    },
    UpdateProjects: ({ commit }, projects) => {
      return new Promise(resolve => {
        commit('UPDATE_PROJECTS', projects)
        resolve()
      })
    }
  }
}

export default app
