// import _ from 'underscore'

/**
 * 路由配置
 * @type {Object}
 */
const routesConfig = {

  system: '系统管理',
  adminList: '管理员管理',
  adminNew: '新建管理员',
  adminEdit: {
    name: '管理员详情',
    paramName: 'id'
  },
  roleList: '角色管理',
  roleNew: '新建角色',
  roleEdit: {
    name: '角色详情',
    paramName: 'id'
  },
  rolePermissions: {
    name: '角色权限配置',
    paramName: 'id'
  },
  roleMiniAppPermissions: {
    name: '小程序权限配置',
    paramName: 'id'
  },
  customerList: '员工管理',
  customerNew: '新建员工',
  customerEdit: {
    name: '员工详情',
    paramName: 'id'
  },
  deptList: '部门管理',
  deptNew: '新建部门',
  deptEdit: {
    name: '部门详情',
    paramName: 'id'
  },
  applyNew: '新建单据',
  applyHoldList: '暂存单据',
  applyEdit: {
    name: '标签申请详情',
    paramName: 'id'
  },
  modelEdit: {
    name: '型号详情',
    paramName: 'id'
  },
  modelList: '型号列表',
  projectList: '项目列表',
  projectEdit: {
    name: '项目详情',
    paramName: 'id'
  },
  operationLogDetail: '操作日志详情',
  supplierList: '供应商管理',
  supplierNew: '新建供应商',
  supplierEdit: {
    name: '供应商详情',
    paramName: 'id'
  },
  manufacturerList: '制造商管理',
  manufacturerNew: '新建制造商',
  manufacturerEdit: {
    name: '制造商详情',
    paramName: 'id'
  },
  dealerList: '代垫客户管理',
  dealerNew: '新建代垫客户',
  dealerEdit: {
    name: '代垫客户详情',
    paramName: 'id'
  },
  billsDetails: {
    name: '订单详情',
    paramName: 'id'
  },
  orderDetails: {
    name: '标签生产详情',
    paramName: 'id'
  },
  distributeDetails: {
    name: '分发单详情',
    paramName: 'id'
  },
  standingBookDetails: {
    name: '台账详情',
    paramName: 'id'
  },
  labelDetails: {
    name: '标签详情',
    paramName: 'id'
  },
  /**
   * 获取路由
   * @param  {String | Object} key   路由键
   * @param  {*} param 参数
   * @param  {String | Array} breadcrumb 自定义面包屑
   * @return {Object}       路由数据对象
   */
  getRoute(key, param, breadcrumb) {
    var result = {}
    if (key instanceof Object) {
      result.name = key.name
      result.params = {}
      if (param) {
        result.params[key.paramName] = param
      }
      if (breadcrumb) {
        result.params['bread_crumb'] = breadcrumb
      }
    } else {
      result.name = key
    }
    return result
  },
  /**
   * 获取带query参数的路由
   * @param  {String | Object} key   路由键
   * @param  {Object} query 参数
   * @param  {String | Array} breadcrumb 自定义面包屑
   * @return {Object}       路由数据对象
   */
  getRouteWithQuery(key, query, breadcrumb) {
    var result = {}
    if (key instanceof Object) {
      result.name = key.name
    } else {
      result.name = key
    }
    result.query = query
    if (breadcrumb) {
      result.params = {}
      result.params['bread_tail'] = breadcrumb
    }
    return result
  }
}

export default routesConfig