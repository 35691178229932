import api from '../api'
/**
 * 管理员用户相关api
 */
export class AdminApi extends api {
  /**
   * 登陆
   * @param {*} param0
   */
  login ({
    account,
    password,
    captcha,
    randomId
  }) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/authenticate`,
      method: 'post',
      data: {
        account,
        password,
        captcha,
        randomId
      }
    })
  }

  /**
   * 获取账号信息
   * @param {*} param0
   */
  getInfo () {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/info`,
      method: 'post'
    })
  }

  getAccountInfo () {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/accountInfo`,
      method: 'post'
    })
  }

  /**
   * 登出
   * @param {*} param0
   */
  logout () {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/logout`,
      method: 'post'
    })
  }
  // logout ({
  //   token
  // }) {
  //   return fetch({
  //     url: `${this.servicePath}/${this.modulePath}/logout`,
  //     method: 'post'
  //   })
  // }

  /**
   * 获取管理员列表
   * @param {*} query 查询条件
   */
  getList (query) {
    return this.getQuery({
      pageInfo: query.pageInfo,
      condition: query.condition
    }, 'getAdmins')
  }

  /**
   * 创建新的管理员
   * @param {*} 对象内容
   */
  insert (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/register`,
      method: 'post',
      data: form
    })
  }

  setState (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/setAccountStatus`,
      method: 'put',
      data: form
    })
  }

  changePassword (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/changeAccountPassword`,
      method: 'post',
      data: form
    })
  }
  resetPassword (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/resetAccountPassword`,
      method: 'post',
      data: form
    })
  }
}

export default new AdminApi('admin')
